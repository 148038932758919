import React, { useState } from "react";
import { useHistory } from "react-router";
import { Form, Label, Button, InputGroup, FormControl } from "react-bootstrap";
import { Logo } from "../../utils/Svg";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  EmptyLocalStorage,
  GetAuthUser,
  Login,
} from "../../services/auth/auth.service";
import {constants, POWERED_BY} from "../../utils/constants";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FadeLoader } from "react-spinners";

function Signin() {
  const history = useHistory();
  const [pass, TogglePass] = useState(true);
  const [loader, setLoader] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    try {
      setLoader(true);
      const loginData = await Login(data.email, data.password, "admin");
      if (!loginData.error) {
        setLoader(false);
        toast.success(loginData.msg);
        history.push("/dashboard");
      } else {
        setLoader(false);
        toast.error(loginData.msg);
      }
    } catch (error) {
      setLoader(false);
      if (error.response && error.response.status === 401) {
        EmptyLocalStorage();
        history.push("/");
      } else {
        toast.error(
          error.response ? error.response.data.msg : "An error occurred"
        );
      }
    }
  };

  return (
    <div className={"text-center authLayout"}>
      <Form className={"form-signin"} onSubmit={handleSubmit(onSubmit)}>
        <a href="/">
          <Logo />
        </a>
        <h1 className="h5 mb-3 font-weight-normal ">Sign in to your account</h1>
        <Form.Group className="mb-3">
          <Form.Control
            placeholder="Email / Username"
            id={"formSignInEmail"}
            {...register("email", {
              required: {
                value: "required",
                message: "Email/User name is required",
              },
              maxLength: {
                value: 255,
                message: "Max length is 255",
              },
              // pattern: {
              //   value: /\S+@\S+\.\S+/,
              //   message: "Entered value does not match email format",
              // },
            })}
            type="text"
          />
          {errors.email && (
            <span className={"validation-error pr-5"} role="alert">
              {errors.email.message}
            </span>
          )}

          {/*password*/}
          <div className={"pass-field mt-3"}>
            <InputGroup controlId="passowrd">
              <FormControl
                placeholder="Enter Password"
                autoComplete={"off"}
                {...register("password", {
                  required: {
                    value: "required",
                    message: "Password is required",
                  },
                  minLength: {
                    value: 6,
                    message: "Min length is 6",
                  },
                  maxLength: {
                    value: 255,
                    message: "Max length is 255",
                  },
                })}
                type={pass ? "password" : "text"}
              />
              <Button
                variant="outline-secondary"
                id="button-addon2"
                onClick={() => TogglePass(!pass)}
              >
                {pass ? <FaEye /> : <FaEyeSlash />}
              </Button>
            </InputGroup>
            {errors.password && (
              <span className={"validation-error pr-5"} role="alert">
                {errors.password.message}
              </span>
            )}
          </div>
          {/*password*/}
        </Form.Group>

        <ul className={"list-unstyled mt-3 d-block"}>
          <li className={"d-block text-end"}>
            <NavLink to={"/forgot-password"}>Forgot Password</NavLink>
          </li>
        </ul>
        <div className="d-grid gap-2 loader_custom">
          {loader ? (
            <div className="spin-loader">
              {" "}
              <FadeLoader color={"rgb(139 77 198)"} height={10} />
            </div>
          ) : (
            <Button
              variant="primary"
              size="lg"
              type="submit"
              className={"btn-green-theme text-uppercase"}
            >
              Sign In
            </Button>
          )}
        </div>
        <p className="mt-5 mb-3 text-muted">
          &copy; All rights reserved by {POWERED_BY} {new Date().getFullYear()}
        </p>
      </Form>
    </div>
  );
}

export default Signin;
